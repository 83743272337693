<script setup lang="ts">
	import { MzButton, MzInput } from '@monizze/monizze-components';
	import MzModal from '@/components/MzModal.vue';
	import { useI18n } from 'vue-i18n';

	const emit = defineEmits<{
		(e: 'close'): void;
		(e: 'submit'): void;
	}>();
	const email = defineModel<string>({ required: true });
	const { t } = useI18n();
</script>

<template>
	<mz-modal :title="t('user.mfa.setup.email.title')" @close="emit('close')">
		<template #content>
			<p>{{ t('user.mfa.setup.email.description') }}</p>
			<mz-input v-model="email" :label="t('user.profile.contact.email')" required minlength="3" class="w-full mb-2" type="email" />
		</template>
		<template #footer>
			<mz-button type="minimal" :label="t('general.cancel')" :width-auto="true" @click="emit('close')" />
			<mz-button :label="t('user.mfa.setup.send')" :width-auto="true" @click="emit('submit')" />
		</template>
	</mz-modal>
</template>

<style scoped></style>
