<script setup lang="ts">
	import UserSidebar from '@/components/UserSidebar.vue';
	import MzCard from '@/components/MzCard.vue';
	import { MzToggle, MzButton, MzLoader } from '@monizze/monizze-components';
	import { useI18n } from 'vue-i18n';
	import { useUserStore } from '@/stores/user.ts';
	import { computed, ref, type Ref } from 'vue';
	import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
	import { faPencil, faPowerOff, faMessage, faEnvelope } from '@fortawesome/pro-solid-svg-icons';
	import { useRoute, useRouter } from 'vue-router';
	import { onBeforeMount, watch } from 'vue';
	import MzWrapper from '@/components/MzWrapper.vue';
	import { Api } from '@/utils/api.ts';
	import { useAuthStore } from '@/stores/auth.ts';
	import PhoneConfirm from '@/components/Mfa/PhoneConfirm.vue';
	import EmailEdit from '@/components/Mfa/EmailEdit.vue';
	import EmailConfirm from '@/components/Mfa/EmailConfirm.vue';
	import PhoneEdit from '@/components/Mfa/PhoneEdit.vue';
	import DeviceInfo from '@/components/Device/DeviceInfo.vue';
	import { useDevicesStore } from '@/stores/devices.ts';
	import { LatestDeviceInfoDetail } from '@/types/Responses/LatestDeviceInfo.ts';

	const { t } = useI18n();

	const hasMfa = computed(() => useUserStore().mfaMethods.length > 0);

	const showMfa: Ref<boolean> = ref(false);
	const route = useRoute();
	const router = useRouter();
	const userStore = useUserStore();
	const authStore = useAuthStore();
	const devicesStore = useDevicesStore();
	const validationCode: Ref<string> = ref('');
	const mfaPayload: Ref<string> = ref('');
	const validationError: Ref<boolean> = ref(false);
	const emailForm: Ref<string> = ref('');
	const phoneForm: Ref<string> = ref('');
	const displayedModal: Ref<string> = ref('');
	const lastDevices: Ref<null | LatestDeviceInfoDetail[]> = ref(null);

	onBeforeMount(async () => {
		watch(showMfa, async (value: boolean, oldValue: boolean) => {
			if (!oldValue && value && userStore.mfaMethods.length === 0) {
				showMfa.value = false;
				if (!(await userStore.isMfa())) {
					await router.push('/mfa');
					return;
				}
				emailForm.value = userStore.user.email;
				phoneForm.value = userStore.user.phone || '';
				displayedModal.value = 'phone';
			}
		});

		lastDevices.value = await devicesStore.fetchLastDevices();
	});

	if (userStore.mfaMethods.length === 0) {
		userStore.refreshMfa().then(() => {
			showMfa.value = userStore.mfaMethods.length > 0;
		});
	} else {
		showMfa.value = true;
	}

	const globalLogout = async () => {
		Api.delete('/tokens').then(() => {
			authStore.logout();
			router.push('/login/email');
		});
	};

	const editPassword = async () => {
		if (!(await userStore.isMfa())) {
			await router.push({ name: 'mfa', query: { single: 'true' } });
			return;
		} else {
			await router.push({ name: 'user-password' });
		}
	};

	const handleLoginSubmit = async (type: string) => {
		if (userStore.user.email === emailForm.value && userStore.user.phone === phoneForm.value) {
			return;
		}

		type Body = {
			email?: string;
			phone?: string;
		};

		let body: Body = {};
		if (userStore.user.email !== emailForm.value) {
			body.email = emailForm.value;
		}
		if (userStore.user.phone !== phoneForm.value) {
			body.phone = phoneForm.value;
		}

		await Api.post('/user/login', body);

		if (type === 'phone') {
			displayedModal.value = 'phone_confirm';
		} else {
			displayedModal.value = 'email_confirm';
		}
	};

	const handleLoginMfa = async (type: string) => {
		if (type === 'phone') {
			if (userStore.user.phone !== phoneForm.value) {
				mfaPayload.value = '';
				await handleLoginSubmit('phone');
			} else {
				const output = await Api.get('/mfa/sms/activate');
				const data = await output.json();
				mfaPayload.value = data.payload;
			}
			displayedModal.value = 'phone_confirm';
		}
		if (type === 'email') {
			if (userStore.user.email !== emailForm.value) {
				mfaPayload.value = '';
				await handleLoginSubmit('email');
			} else {
				const output = await Api.get('/mfa/email/activate');
				const data = await output.json();
				mfaPayload.value = data.payload;
			}
			displayedModal.value = 'email_confirm';
		}
	};

	const handleConfirmMfa = async (type: string) => {
		try {
			if (mfaPayload.value.length > 0) {
				await Api.post('/mfa/activate', { code: validationCode.value, payload: mfaPayload.value });
			} else {
				await Api.post('/user/login/confirm', { code: validationCode.value });
				await userStore.refreshUser();
			}
			validationCode.value = '';
			mfaPayload.value = '';
			validationError.value = false;
			if (type === 'phone') {
				displayedModal.value = 'email';
			} else {
				displayedModal.value = '';
				await userStore.refreshMfa();
				userStore.refreshMfa().then(() => {
					showMfa.value = userStore.mfaMethods.length > 0;
				});
			}
		} catch (e) {
			validationError.value = true;
		}
	};

	const redirectToPrivacy = () => {
		window.location.href = t('user.security.privacy.link');
	};

	// Auto-enable MFA flow
	if (route.query.mfa === 'true') {
		emailForm.value = userStore.user.email;
		phoneForm.value = userStore.user.phone || '';
		userStore.refreshMfa().then(() => {
			if (userStore.mfaMethods.length === 0) {
				// BR force MFA setup flow if not set
				displayedModal.value = 'phone';
			}
		});
	}
</script>

<template>
	<user-sidebar />
	<!-- FIRST MFA MODAL - PHONE -->
	<phone-edit v-if="displayedModal === 'phone'" v-model="phoneForm" @close="displayedModal = ''" @submit="handleLoginMfa('phone')" />
	<div></div>
	<!-- SECOND MFA MODAL - PHONE CONFIRM -->
	<phone-confirm
		v-if="displayedModal === 'phone_confirm'"
		v-model="validationCode"
		:phone="phoneForm"
		:error="validationError"
		:allow-edit="true"
		@close="displayedModal = ''"
		@edit="displayedModal = 'phone'"
		@submit="handleConfirmMfa('phone')"
		@resend="handleLoginMfa('phone')"
	/>
	<!-- THIRD MFA MODAL - EMAIL -->
	<email-edit v-if="displayedModal === 'email'" v-model="emailForm" @close="displayedModal = ''" @submit="handleLoginMfa('email')" />
	<!-- FOURTH MFA MODAL - EMAIL CONFIRM -->
	<email-confirm
		v-if="displayedModal === 'email_confirm'"
		v-model="validationCode"
		:email="emailForm"
		:error="validationError"
		:allow-edit="true"
		@close="displayedModal = ''"
		@edit="displayedModal = 'email'"
		@submit="handleConfirmMfa('email')"
		@resend="handleLoginMfa('email')"
	/>
	<mz-wrapper :title="t('user.security.title')" :intro="t('user.security.subtitle')">
		<mz-card :title="t('user.security.password')">
			<template #content>
				<div class="info-row">
					<p>{{ t('user.security.last-change') }}</p>
					<p v-if="userStore.user.last_password_change">{{ new Date(userStore.user.last_password_change).toLocaleString() }}</p>
					<p v-else>{{ t('user.security.never') }}</p>
				</div>
				<mz-button
					type="tertiary"
					:label="t('user.security.edit-password')"
					:action="() => editPassword()"
					icon-position="left"
					:icon="faPencil"
					:width-auto="true"
				/>
			</template>
		</mz-card>
		<mz-card :title="t('user.security.enable.title')">
			<template #content>
				<div class="flex flex-col gap-4">
					<div class="flex justify-between gap-10 items-center">
						<p class="text-info-large">{{ t('user.security.enable.description') }}</p>
						<mz-toggle v-model="showMfa" :disabled="hasMfa" />
					</div>
					<div v-if="showMfa" class="mfa-row">
						<section>
							<FontAwesomeIcon :icon="faMessage" />
							<div>
								<p>{{ t('user.security.mfa.methods.sms.title') }}</p>
								<p>{{ t('user.security.mfa.methods.sms.description') }}&nbsp;{{ useUserStore().user.phone }}</p>
							</div>
						</section>
						<mz-button
							type="tertiary"
							:label="t('user.security.mfa.edit')"
							:width-auto="true"
							@click="router.push({ name: 'user-profile' })"
						/>
					</div>
					<div v-if="showMfa" class="mfa-row">
						<section>
							<FontAwesomeIcon :icon="faEnvelope" />
							<div>
								<p>{{ t('user.security.mfa.methods.email.title') }}</p>
								<p>{{ t('user.security.mfa.methods.email.description') }}&nbsp;{{ useUserStore().user.email }}</p>
							</div>
						</section>
						<mz-button
							type="tertiary"
							:label="t('user.security.mfa.edit')"
							:width-auto="true"
							@click="router.push({ name: 'user-profile' })"
						/>
					</div>
				</div>
			</template>
		</mz-card>
		<mz-card class="mb-9 mt-9" :title="t('user.security.device.title')">
			<template #content>
				<div class="text-info-large">
					<p>{{ t('user.security.device.subtitle') }}</p>
				</div>
				<div v-if="lastDevices === null" class="h-60">
					<mz-loader />
				</div>
				<template v-else>
					<div v-for="(device, key) in lastDevices" :key="key" class="mx-9">
						<hr v-if="key !== 0" />
						<device-info :device="device" />
					</div>
				</template>
				<mz-button
					class="self-start"
					type="tertiary"
					:label="t('user.security.device.button')"
					:action="() => $router.push({ name: 'user-security-history' })"
					:width-auto="true"
				/>
			</template>
		</mz-card>
		<mz-card :title="t('user.security.logout.title')">
			<template #content>
				<div class="flex flex-col gap-6">
					<p class="text-info-large">{{ t('user.security.logout.description') }}</p>
					<mz-button
						type="tertiary"
						:label="t('user.security.logout.action')"
						:action="() => globalLogout()"
						:width-auto="true"
						icon-position="left"
						:icon="faPowerOff"
						class="self-start"
					/>
				</div>
			</template>
		</mz-card>
		<mz-card :title="t('user.security.privacy.title')">
			<template #content>
				<div class="flex flex-col gap-6">
					<p class="text-info-large">{{ t('user.security.privacy.description') }}</p>
					<mz-button
						class="self-start"
						type="tertiary"
						:label="t('user.security.privacy.action')"
						:action="() => redirectToPrivacy()"
						:width-auto="true"
					/>
				</div>
			</template>
		</mz-card>
	</mz-wrapper>
</template>

<style lang="sass" scoped>
	.info-row
		@apply flex w-full flex-wrap border-b border-global-border py-4 gap-4 sm:gap-0
		p:first-of-type
			@apply text-label-medium
		p:last-of-type
			@apply text-info-large
		p
			@apply w-full sm:w-1/2

	.info-row:last-of-type
		@apply border-none

	.mfa-row
		@apply flex w-full md:flex-row justify-between flex-col items-center sm:gap-10 gap-6
		section
			@apply flex sm:gap-6 gap-4 justify-center sm:items-center items-start
		div
			@apply flex flex-col gap-2 w-full
			p:first-of-type
				@apply text-global-h6
			p:last-of-type
				@apply text-info-large
</style>
