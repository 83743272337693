<script setup lang="ts">
	import UserSidebar from '@/components/UserSidebar.vue';
	import MzCard from '@/components/MzCard.vue';
	import MzWrapper from '@/components/MzWrapper.vue';
	import MzActionRow from '@/components/MzActionRow.vue';
	import { useI18n } from 'vue-i18n';
	import { useBrowserLocation } from '@vueuse/core';
	import { useUserStore } from '@/stores/user.ts';
	import { useAuthStore } from '@/stores/auth.ts';

	const { t } = useI18n();
	const fullURL: string = useBrowserLocation().value.href || '';
	const url: URL = new URL(fullURL);
	const domain: string = `${url.protocol}//${url.hostname}`;
	const userStore = useUserStore();
	const authStore = useAuthStore();
</script>

<template>
	<user-sidebar />
	<mz-wrapper :title="t('user.dashboard.welcome', { firstname: userStore.user.firstname })" :intro="t('user.dashboard.subtitle')">
		<mz-card :title="t('user.dashboard.services')">
			<template #content>
				<div class="flex flex-col gap-6">
					<mz-action-row
						v-if="userStore.user.customer_user_id"
						:href="domain.replace('account', 'client')"
						:title="t('partner.page.client-area')"
						:alt="t('partner.page.client-area')"
						is-platform-row
						source="/Partner/client-area.svg"
					/>
					<mz-action-row
						v-if="userStore.user.user_id"
						:href="domain.replace('account', 'my')"
						:title="t('partner.page.my-monizze')"
						:alt="t('partner.page.my-monizze')"
						is-platform-row
						:source="'/Partner/my-' + (authStore.isLux ? 'up' : 'monizze') + '.svg'"
					/>
					<mz-action-row
						v-if="userStore.user.fridom_user_id"
						:href="domain.replace('account', 'fridom')"
						:title="t('partner.page.fridom')"
						:alt="t('partner.page.fridom')"
						is-platform-row
						source="/Partner/fridom.svg"
					/>
				</div>
			</template>
		</mz-card>
	</mz-wrapper>
</template>

<style lang="sass" scoped></style>
